import React, { Component }  from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import './App.css';
import ProgressBar from './components/ProgressBar';
import Question from './components/Question';
import Submitted from './components/Submitted';
import { fetchForm } from './redux/Form/form.actions';

class App extends Component {
  componentDidMount() {
    this.props.getFormData();
  }

  render () {
    return (
      <div className="App">
        <Helmet>
          <title>{this.props.form.formContent.title}</title>
        </Helmet>
        {this.props.form.isLoading ? <p></p> : 
        <React.Fragment>
        <div className="formHeader">
          <ProgressBar/>
        </div>
        {this.props.form.currentStep <= this.props.form.totalSteps ? <Question /> : <Submitted />}
      </React.Fragment>}
      </div>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
  getFormData: fetchForm
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));