import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import './Submitted.scss';

class Submitted extends Component {
    render () {
        return <div className="submitted">
            <div className="subSubmit">
                <h2>{this.props.form.formContent.submittedPage.title}</h2>
                <p>{this.props.form.formContent.submittedPage.subTitle}</p>
            </div>
        </div>
    }

}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withRouter(Submitted));