/*eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }]*/
import { NEXT_STEP, UPDATE_VALUE, FETCH_FORM, FETCHED_FORM, PREVIOUS_STEP } from './form.types';
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const INITIAL_STATE = {
    currentStep: 0,
    totalSteps: 0,
    isLoading: true,
    hasError: false,
    errorMessage: '',
    formContent: {
        title: '',
        questions: [],
        submittedPage: {
            title: '',
            subTitle: ''
        }
    }
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEXT_STEP:
            //Field validation
            let isValid = true;
            let errorMessage = ''
            let questionValue = state.formContent.questions[state.currentStep - 1].value;
            let questionType = state.formContent.questions[state.currentStep - 1].type;
            switch(questionType) {
                case 'yes_no':
                    break
                case 'full_name':
                    if(questionValue.length === 0) {
                        isValid = false;
                        errorMessage = 'Please enter your name.';
                    }
                    break
                case 'email':
                    if(!EMAIL_REGEX.test(questionValue)) {
                        isValid = false;
                        errorMessage = 'Please enter a valid email address.';
                    }
                    break
                case 'phone':
                    if(questionValue.length === 0) {
                        isValid = false;
                        errorMessage = 'Please enter your phone number.';
                    }
                    // caution: break is omitted intentionally
                case 'text':
                    if(questionValue.length === 0) {
                        isValid = false;
                        errorMessage = 'Field can not be left blank.';
                    }
                    // caution: break is omitted intentionally
                default:

                    break
            }

            if(!isValid) {
                return {
                    ...state,
                    hasError: true,
                    errorMessage: errorMessage
                }
            }

            return {
                ...state,
                currentStep: state.currentStep + 1,
                hasError: false,
                errorMessage: ''
            };
        case PREVIOUS_STEP:
            return {
                ...state,
                currentStep: state.currentStep - 1,
                hasError: false,
                errorMessage: ''
            };
        case UPDATE_VALUE:
            let targetedQuestionIndex = state.formContent.questions.findIndex((i) => i.section === action.field);
            let targetedQuestion = state.formContent.questions[targetedQuestionIndex];
            targetedQuestion.value = action.value;
            let currentQuestions = state.formContent.questions;
            currentQuestions[targetedQuestionIndex] = targetedQuestion;
            return {
                ...state,
                hasError: false,
                errorMessage: '',
                formContent: {
                    ...state.formContent,
                    questions: currentQuestions
                }
            };
        case FETCH_FORM:
            return {
                ...state,
                isLoading: true
            }
        case FETCHED_FORM:
            console.log(action)
            return {
                ...state,
                isLoading: false,
                formContent: action.formData,
                currentStep: 1,
                totalSteps: action.formData.questions.length
            }
        default: return state;
    }
};

export default reducer;