import { combineReducers } from 'redux';


import formReducer from './Form/form.reducer';


const rootReducer = combineReducers({
    form: formReducer,
});

export default rootReducer;