import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {NEXT_STEP, UPDATE_VALUE} from '../redux/Form/form.types';
import './Button.scss';

class YesNoButton extends Component {


    clickedButton = (action) => {
        this.props.updateValue(this.props.id, action);
        this.props.nextPage();
    }

    render () {
        return <div className="yesno-container">
            <button className="button button-yes" onClick={() => this.clickedButton('yes')}>Yes</button>
            <button className="button button-no" onClick={() => this.clickedButton('no')}>No</button>
        </div>
    }

}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        nextPage: () => dispatch({type: NEXT_STEP}),
        updateValue: (field, value) => dispatch({type: UPDATE_VALUE, field, value})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YesNoButton));