import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import './ProgressBar.scss';

class ProgressBar extends Component {

    render () {
        return <React.Fragment>
            <h3>{this.props.form.formContent.title}</h3>
           {(this.props.form.currentStep <= this.props.form.totalSteps) && <p>Question {this.props.form.currentStep} of {this.props.form.totalSteps}</p>}
            <div style={{width: (100 / (this.props.form.totalSteps + 1)) * this.props.form.currentStep + '%'}}className="formHeaderProgress"/>
        </React.Fragment>
    }

}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withRouter(ProgressBar));