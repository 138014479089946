import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {NEXT_STEP} from '../redux/Form/form.types';
import './Button.scss';

class NextButton extends Component {

    render () {
        return <button className="button" onClick={() => this.props.nextPage()}>Next</button>
    }

}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        nextPage: () => dispatch({type: NEXT_STEP})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NextButton));