/*eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }]*/
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import NextButton from './NextButton';
import YesNoButton from './YesNoButton';
import './Question.scss';
import { UPDATE_VALUE, NEXT_STEP, PREVIOUS_STEP } from '../redux/Form/form.types';

const skipNextButton = ['yes_no'];
class Question extends Component {


    render () {

        let currentQuestion = this.props.form.formContent.questions[this.props.form.currentStep - 1];
        let questionComponent;
        let autofillType = 'on';
        switch(currentQuestion.type) {
            case 'yes_no':
                questionComponent = <YesNoButton id={currentQuestion.section}/>
                break
            case 'phone':
                questionComponent = <input 
                type='tel'
                autoComplete='tel'
                name={currentQuestion.section}
                id={currentQuestion.section}
                value={currentQuestion.value}
                onChange={this.props.updateValue}
                onKeyPress={this.props.keyPressed}
                className={this.props.form.hasError ? 'input-error' : ''}
                />;
                break
            case 'full_name':
                if(autofillType === 'on') autofillType = 'name';
                // caution: break is omitted intentionally
            case 'email':
                if(autofillType === 'on') autofillType = 'email';
                // caution: break is omitted intentionally
            case 'text':
                // caution: break is omitted intentionally
            default:
                questionComponent = <input type='text'
                autoComplete={autofillType}
                name={currentQuestion.section}
                id={currentQuestion.section}
                value={currentQuestion.value}
                onChange={this.props.updateValue}
                onKeyPress={this.props.keyPressed}
                className={this.props.form.hasError ? 'input-error' : ''}
                />;
                break
        }
        return <div className="questionContainer">
                <div className="question">
                    {this.props.form.currentStep !== 1 && <p className="previousQuestion" onClick={() => this.props.previousStep()}>← Previous Question</p>}
                    <h2>{currentQuestion.question}</h2>
                    
                    {questionComponent}
                    {<p className='input-error-warning'>{ this.props.form.hasError && this.props.form.errorMessage}</p>}
                    <br/>
                    {!skipNextButton.includes(currentQuestion.type) && <NextButton />}
                </div>
            </div>;
    }

}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        updateValue: (f) => {
            dispatch({type: UPDATE_VALUE, field: f.target.id, value: f.target.value})
        },
        keyPressed: (e) => {
            if(e.key === 'Enter') {
                dispatch({type: NEXT_STEP})
            }
        },
        nextStep: (e) => dispatch({type: NEXT_STEP}),
        previousStep: () => dispatch({type: PREVIOUS_STEP})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Question));