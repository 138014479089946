import React  from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import store from './redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LogRocket from 'logrocket';
import { hotjar } from 'react-hotjar';
LogRocket.init('w6b5gm/flowpile');

ReactDOM.render(
  <Provider store={store}>
      <Router>
        <Route path="/" component={App}/>
      </Router>
  </Provider>,
  document.getElementById('root')
);

hotjar.initialize(2234466, 6);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
