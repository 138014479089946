import { FETCH_FORM, FETCHED_FORM } from './form.types';

//const API_SERVER = 'http://localhost:8080/api';
const API_SERVER = 'https://api.flowpile.com/api';
export const fetchFormData = () => {
    return {
        type: FETCH_FORM
    }
}

export const fetchedFormData = formData => {
    return {
        type: FETCHED_FORM,
        formData
    }
}

export function fetchForm() {
    return function(dispatch) {
        dispatch(fetchFormData());
        //Fetch rfom API
        return fetch(`${API_SERVER}/question`).then((response) => response.json(), error => console.log('An error occured.',error),
        ).then((json) => {
            dispatch(fetchedFormData(json));
        });
    }
}